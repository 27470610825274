import { useEffect, useState } from "react";
import { Row, Container, Table, Button } from "react-bootstrap";
import {
  deletePlayer,
  getAllPlayers,
  validatePlayer,
} from "./../resources/db";
import Modal from "react-bootstrap/Modal";
import ToggleButton from "react-bootstrap/ToggleButton";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import { doc, collection, query, onSnapshot } from "firebase/firestore";
import { db } from "../firebase";

function isImgUrl(url) {
  const img = new Image();
  img.src = url;
  return new Promise((resolve) => {
    img.onerror = () => resolve(false);
    img.onload = () => resolve(true);
  });
}

export default function Admin() {
  const [players, setPlayers] = useState([]);
  const [show, setShow] = useState();
  const [showConfirm, setShowConfirm] = useState();
  const [activeImage, setActiveImage] = useState();
  useEffect(() => {
    // getAllPlayers().then((data) => {
    //   const _players = data.reduce((pv, cr) => {
    //     if (cr.deleted !== true) {
    //       pv.push(cr);
    //     }
    //     return pv;
    //   }, []);
    //   setPlayers(_players);
    // });
    const unsub = onSnapshot(collection(db, "players"), (snapshot) => {
      let players = [];
      snapshot.forEach((doc) => {
        if (doc.data().deleted != true) {
          players.push({ id: doc.id, ...doc.data() });
        }
      });
      setPlayers(players);
    });
    return unsub;
  }, []);
  function getAge(dateString) {
    var today = new Date();
    var birthDate = new Date(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  }
  return (
    <Container>
      <Row className="justify-content-center">
        <h2>Jugadores registrados:</h2>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>#</th>
              <th>Nombre</th>
              <th>Telefono</th>
              <th>Ciudad</th>
              <th>Fecha de nacimiento</th>
              <th>Edad</th>
              <th>CURP</th>
              <th>Comprobante de pago</th>
              <th>Validado</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {players.map((player, i) => (
              <tr key={player.id}>
                <td>{i + 1}</td>
                <td>
                  {player.name +
                    " " +
                    player.paternalName +
                    " " +
                    player.maternalName}
                </td>
                <td>{player.cellphone}</td>
                <td>{player.city}</td>
                <td>{player.birthDate}</td>
                <td>{getAge(player.birthDate)}</td>
                <td>
                  <Button
                    variant="primary"
                    type="button"
                    onClick={async () => {
                      // const isImg = await isImgUrl(player.curp)
                      //   .then(true)
                      //   .catch(false);
                      // console.log("isImag", isImg);
                      // setFileType(isImg ? "img" : "pdf");
                      setActiveImage(player.curp);
                      setShow(true);
                    }}
                  >
                    Ver
                  </Button>
                </td>
                <td>
                  {player.voucher && player.voucher != "urlVoucher" && (
                    <>
                      <Button
                        variant="primary"
                        type="button"
                        onClick={async () => {
                          // const isImg = await isImgUrl(player.voucher)
                          //   .then(true)
                          //   .catch(false);
                          // console.log("isImag", isImg);
                          // setFileType(isImg ? "img" : "pdf");
                          setActiveImage(player.voucher);
                          setShow(true);
                        }}
                      >
                        Ver
                      </Button>
                    </>
                  )}
                </td>
                <td>
                  <BootstrapSwitchButton
                    checked={player.validated}
                    onlabel="Si"
                    offlabel="No"
                    onChange={(checked) => {
                      validatePlayer(player.id, checked);
                    }}
                  />
                </td>
                <td>
                  <Button
                    variant="danger"
                    onClick={() => {
                      setShowConfirm(player.id);
                    }}
                  >
                    Borrar
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Row>
      <Modal
        show={showConfirm}
        onHide={function () {
          setShowConfirm(null);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirmar</Modal.Title>
        </Modal.Header>
        <Modal.Body>Estas seguro de borrar este registro?</Modal.Body>
        <Modal.Footer>
          <Button
            variant="danger"
            onClick={function () {
              deletePlayer(showConfirm);
              setShowConfirm(null);
            }}
          >
            Borrar
          </Button>
          <Button
            variant="secondary"
            onClick={function () {
              setShowConfirm(null);
            }}
          >
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        fullscreen={true}
        show={show}
        onHide={function () {
          setActiveImage(null);
          setShow(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Modal heading</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <object width="100%" height="100%" data={activeImage}>
            {" "}
          </object>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={function () {
              setActiveImage(null);
              setShow(false);
            }}
          >
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}
